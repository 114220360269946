import axios from '../resources/js/request'

const request = axios.request;

/**
 * 用户相关
 */
export default {
  checkPersonalVerified({userId, phone, username, idNumber,realnameAuthFlag}) {
    return request.doPost({
      reqcode: '1141',
      reqdesc: '实名校验',
      userId: userId,
      phone: phone,
      username: username,
      identity_number: idNumber,
      realnameAuthFlag:realnameAuthFlag
    });
  },
  /**
   * 上传收款码
   */
  uploadCollectionCode({userId, type, path}) {
    return request.doPost({
      reqcode: 1153,
      reqdesc: '上传收款码',
      userId: userId,
      type: type,
      path: path,
    })
  },
  /**
   * 获取收款码
   * @param userId
   * @returns {*}
   */
  getCollectionCode({userId}) {
    return request.doPost({
      reqcode: 1154,
      reqdesc: '获取收款码',
      userId: userId,
    })
  },
  userInfo({userId}) {
    return request.doPost({
      reqcode: '1042',
      reqdesc: '获取用户信息',
      userId,
      t: new Date().getTime(),
    })
  },
  submitRiskWarningImg({userId, imgUrl}) {
    return request.doPost({
      reqcode: 1109,
      reqdesc: 'submit risk warning',
      userId, imgUrl
    })
  },
  getRiskWarning({userId}) {
    return request.doPost({
      reqcode: 1204,
      reqdesc: 'get risk warning',
      userId,
    })
  },
  limitAccessInfo({userId}) {
    return request.doPost({
      reqcode: 1205,
      reqdesc: 'limit access',
      userId,
    });
  },
  viewContactUsers({id, type, userId}) {
    return request.doPost({
      reqcode: '1060',
      reqdesc: 'view users for contact',
      id, type, userId,
    })
  },
  changePassword({userId, password, code}) {
    return request.doPost({
      reqcode: '1228',
      reqdesc: 'update password',
      password: password,
      code: code,
      userId: userId,
    })
  },
  uploadWxQrcode({userId, path}) {
    return request.doPost({
      reqcode: 1252,
      reqdesc: 'upload wx qrcode',
      userId, path
    })
  },
  loginByUsernamePassword({username, password, rememberMe}) {
    return request.doPost({
      reqcode: '1001',
      reqdesc: '用户登录',
      username: username,
      password: password,
      rememberMe,
      vflag: 1,
    })
  },
  loginByCaptchaCode({phone, code, autoRegister}) {
    return request.doPost({
      reqcode: '1037',
      reqdesc: '手机验证码登录',
      vflag: 1,
      phone, code, autoRegister,
    })
  },
  loginByToken({token}) {
    return request.doPost({
      reqcode: 1057,
      reqdesc: 'login by token',
      token,
    })
  },
  // 检查账号唯一
  checkAccountUniqueness({account, userId}) {
    return request.doPost({
      reqcode: '1138',
      reqdesc: '账号唯一性校验',
      account: account, userId
    });
  },
  checkEmailUniqueness({email, userId}) {
    return request.doPost({
      reqcode: '1257',
      reqdesc: 'check email',
      valid_cache: new Date().getTime(),
      email, userId,
    })
  },
  saveAuthRegisterUserInfo({userId, account, password}) {
    return request.doPost({
      reqcode: 1255,
      reqdesc: 'save auto register',
      userId, account, password
    })
  },
  fundingDetails(params) {
    return request.doPost({
      reqcode: '1162',
      reqdesc: '我的资金流动明细',
      ...params
    });
  },
  fundingDetailsStatistics({userId}) {
    return request.doPost({
      reqcode: '1149',
      reqdesc: '',
      userId,
    });
  },
  // 保存企业基本信息（可修改保存）
  saveBusinessInformation({userId, industryType, enterpriseSize, totalStaff, companyWebsite, companyProfile,}) {
    return request.doPost({
      reqcode: 1006,
      reqdesc: 'save business information',
      userId,
      industryType,
      enterpriseSize,
      totalStaff,
      companyWebsite,
      companyProfile
    })
  },
}
