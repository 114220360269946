export default {
  namespaced: true,
  state: {
    isMobile: false,
    websiteTitle: '', // 网站标题
    contactUsQQ: '', // 联系我们qq
    language: '', // 语言
    contactUsPhone: '', // 联系客服手机
    contactUsTel: '', // 联系客服电话
    contactUsTime: '', // 联系客服时间
    adminWebSite: '', // 后台地址
    technologyConsultingPhone: '', // 技术咨询电话
    mediaCooperation: '', // 媒体合作
    email: '', // 邮箱
    postcode: '', // 邮编
    contactUsername: '', // 联系人
    companyAddress: '', // 公司地址
    officialSite: '', // 官方网站
    businessQrCode: '', // 商务二维码
    helloWord: '', // Hi，欢迎来到XXXX
    friendLinks: '', // Hi，欢迎来到XXXX
    advertises: '',
    defaultAvatar: '', // 默认用户头像
  },
  mutations: {
    setDevice: function (state, isMobile) {
      state.isMobile = isMobile
    },
    setDefaultAvatar: function (state, avatar) {
      state.defaultAvatar = avatar;
    },
    setWebsiteTitle: function (state, title) {
      localStorage.setItem('websiteTitle', title);
      state.websiteTitle = title;
    },
    setContactUsQQ: function (state, qq) {
      state.contactUsQQ = qq
    },
    setLanguage: function (state, lang) {
      localStorage.setItem('lang', lang)
      state.language = lang
    },
    setContactUsPhone: function (state, contactUsPhone) {
      state.contactUsPhone = contactUsPhone
    },
    setContactUsTel: function (state, contactUsTel) {
      state.contactUsTel = contactUsTel
    },
    setContactUsTime: function (state, contactUsTime) {
      state.contactUsTime = contactUsTime
    },
    setAdminWebSite: function (state, adminWebSite) {
      state.adminWebSite = adminWebSite
    },
    setTechnologyConsultingPhone: function (state, technologyConsultingPhone) {
      state.technologyConsultingPhone = technologyConsultingPhone
    },
    setMediaCooperation: function (state, mediaCooperation) {
      state.mediaCooperation = mediaCooperation
    },
    setEmail: function (state, email) {
      state.email = email
    },
    setPostcode: function (state, postcode) {
      state.postcode = postcode
    },
    setContactUsername: function (state, contactUsername) {
      state.contactUsername = contactUsername
    },
    setCompanyAddress: function (state, companyAddress) {
      state.companyAddress = companyAddress
    },
    setOfficialSite: function (state, officialSite) {
      state.officialSite = officialSite
    },
    setBusinessQrCode: function (state, businessQrCode) {
      state.businessQrCode = businessQrCode
    },
    setHelloWord: function (state, helloWord) {
      state.helloWord = helloWord
    },
    setFriendLinks: function (state, friendLinks) {
      state.friendLinks = friendLinks
    },
    setadvertises: function (state, advertises) {
      state.advertises = advertises
    }
  },
  getters: {
    /**
     * 获取当前语言，如果没有设置任何语言，默认使用中文
     * @param state
     * @returns {string|string|*}
     */
    getLanguage: state => {
      if (state.language) {
        return state.language
      } else {
        const language = localStorage.getItem('lang')
        return language || 'zh-cn'
      }
    },
    getWebSiteTitle: (state) => {
      if (state.websiteTitle) {
        return state.websiteTitle
      } else {
        const websiteTitle = localStorage.getItem('websiteTitle')
        if (!websiteTitle) {
          return "默蓝网";
          // return config.getWebSiteTitle()
          // .then(res => {
          //   let {data: title} = res;
          //   localStorage.setItem('websiteTitle', title);
          //   state.websiteTitle = title;
          //   return title;
          // });
        }
        state.websiteTitle = websiteTitle;
        return websiteTitle;
      }
    }
  }
}
