import Vue from 'vue'
import Vuex from 'vuex'
import account from '@STORE/modules/account'
import setting from '@STORE/modules/setting'
import params from "@STORE/modules/params";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    account: account,
    setting: setting,
    params: params,
  }
})
