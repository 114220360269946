import Filter from './interceptor'
import store from "@STORE/index";
import {shop, user} from "@/apis";
import {MessageBox} from "element-ui";

function setTitle(route) {
  const websiteTitle = store.getters['setting/getWebSiteTitle'];
  if (websiteTitle instanceof Promise) {
    websiteTitle.then(res => {
      document.title = `${res} - ${route.name}`
    })
  } else {
    document.title = `${websiteTitle} - ${route.name}`
  }
}

class RouterFilterChain extends Filter.FilterChain {
  execute(to, from, next) {
    if (this.filters && this.filters.length > 0) {
      let doNext = (current) => {
        if (current < this.filters.length - 1) {
          doFilter(this.filters[++current], current);
        } else {
          next();
        }
      }
      let doFilter = (filter, current) => {
        let ret = filter.doFilter(to, from, next);
        if (ret instanceof Promise) {
          ret.then(res => {
            if (res !== false) {
              doNext(current);
            }
          });
        } else {
          if (ret !== false) {
            doNext(current);
          }
        }
      }
      let current = 0;
      let filter = this.filters[current];
      doFilter(filter, current);
      return;
    }
    next();
  }

  afterEach(to, from) {
    setTitle(to);
  }
}

class Routerfilter extends Filter.Filter {
  doFilter(to, from, next) {

  }
}

const checkRouter = (route, callback) => {
  let target = route.matched.filter(
    item => item.path === route.path || item.regex.test(route.path)
  )[0];
  if (target) {
    do {
      if (callback(target)) {
        return true;
      }
    } while ((target = target.parent));
  }
}
// 拦截器链
const routerFilterChain = new RouterFilterChain();
// 登录拦截
const loginFilter = new Routerfilter();
loginFilter.doFilter = (to, from, next) => {
  if (store.getters['account/getUser']) {
    return true;
  }
  let token = localStorage.getItem("token");
  if (!token) {
    return true;
  }
  return user.loginByToken({
    token: token
  }).then(res => {
    let {data} = res;
    if (data) {
      store.commit('account/setUser', data);
    }
  }).catch(() => {
    return true;
  });
}
// 用户登录校验拦截
const requireAuthFilter = new Routerfilter();
requireAuthFilter.doFilter = (to, from, next) => {
  let needLogin = () => {
    return checkRouter(to, (route) => route.meta && route.meta.requireAuth);
  }
  if (needLogin() && !store.getters['account/getUser']) {
    next({path: `/login?t=${new Date().getTime()}`})
  }
}
// 店铺入驻拦截
const requireShopRegisterFilter = new Routerfilter();
requireShopRegisterFilter.doFilter = (to, from, next) => {
  let needShopRegister = () => {
    return checkRouter(to,
      (route) => route.meta && route.meta.requireShopRegister);
  }
  if (needShopRegister()) {
    let user = store.getters['account/getUser'];
    // 如果需要店铺认证，那么执行查询结果
    return shop.checkShopIsRegister({
      userId: user.userId
    }).then(res => {
      let {data} = res;
      if (data!=1) {
        MessageBox.confirm(`您还未入驻，不能发布出售信息，请先完善店铺信息！`, '提示', {
          confirmButtonText: '去入驻',
          cancelButtonText: '取消',
          showCancelButton: true,
        }).then(res => {
          if (res === 'confirm') {
            next('/shop/index')
          }
        });
      }
      return data;
    })
  }
}
// 重复登录页拦截
const loginPageFilter = new Routerfilter();
loginPageFilter.doFilter = (to, from, next) => {
  if (store.getters['account/getUser'] && to.path === '/login') {
    // 已经登录跳转登录页
    next({path: '/'})
  }
}

// 拦截器链添加拦截器
routerFilterChain.addFilter(loginFilter);
routerFilterChain.addFilter(requireAuthFilter);
routerFilterChain.addFilter(requireShopRegisterFilter);
routerFilterChain.addFilter(loginPageFilter);

export default {
  routerFilterChain
}
