import axios from '../resources/js/request'

const request = axios.request;

export default {
  /**
   * 获取兼职列表，当包含userId时，为管理列表
   * @returns {*}
   */
  list({page, limit, userId, workingYear, name, provinceCode, cityCode, skillId, minPrice, maxPrice,orderBy}) {
    return request.doPost({
      reqcode: '1150',
      reqdesc: 'IT兼职中心',
      page, limit, userId, workingYear, name, provinceCode, cityCode, skillId, minPrice, maxPrice,orderBy,
    });
  },
  detail({id, userId}) {
    return request.doPost({
      reqcode: '1151',
      reqdesc: '兼职详情',
      id: id,
      userId: userId,
    });
  },
  loadCondition() {
    return request.doPost({
      reqcode: '1192',
      reqdesc: '',
    });
  },
  loadRecommend() {
    return request.doPost({
      reqcode: '1193',
      reqdesc: 'recommend',
    });
  },
}
