import axios from '../resources/js/request'

const request = axios.request;

export default {
  centerData({typeId, subTypeId, codeTypeId, languageId, dbTypeId, orderBy, goodsName, useFlag, copyright, minPrice, maxPrice, page, limit,}) {
    return request.doPost({
      reqcode: '1035',
      reqdesc: 'sourceCode center',
      typeId, subTypeId, codeTypeId, languageId, dbTypeId, orderBy,
      goodsName, useFlag, copyright, minPrice, maxPrice, page, limit,
    })
  },
  centerRecommend({page, limit}) {
    return request.doPost({
      reqcode: '1132',
      reqdesc: '推荐源码',
      page, limit,
    })
  },
  condition() {
    return request.doPost({
      reqcode: '1011',
      reqdesc: '商品选项'
    })
  },
  editDetail({productId, userId}) {
    return request.doPost({
      reqcode: '1231',
      reqdesc: '商品详情',
      goodsId: productId,
      userId: userId
    })
  },
  /**
   * 暂未使用
   * @deprecated
   */
  persisSourceCode({}) {
    return request.doPost({
      reqcode: '1012',
      reqdesc: '编辑商品',
    })
  }
}
