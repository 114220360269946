import axios from '../resources/js/request'

const request = axios.request;

export default {
  getDefaultAddress({userId}) {
    return request.doPost({
      reqcode: '1097',
      reqdesc: '获取默认地址',
      userId,
    })
  },
  addAddress({defaultFlag, phone, userId, receiver, area, addressDetails, addressId, areaCode}) {
    return request.doPost({
      reqcode: '1028',
      reqdesc: '编辑用户地址',
      defaultFlag,
      phone,
      userId,
      receiver,
      area,
      addressDetails,
      addressId,
      areaCode,
    });
  }
}
