export default {
  namespaced: true,
  state: {
    IpLimitAccessReason: {}
  },
  mutations: {
    setIpLimitAccessReason: function (state, reason) {
      state.IpLimitAccessReason = reason;
    }
  },
  getters: {}
}
