<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {},
  methods: {
    setStoreSettingModule() {
      this.$request.post({
        reqcode: '1036',
        reqdesc: '基本配置'
      }).then(res => {
        this.$store.commit('setting/setContactUsQQ', res.retdata.contactUsQQ)
        this.$store.commit('setting/setContactUsPhone', res.retdata.contactUsPhone)
        this.$store.commit('setting/setContactUsTel', res.retdata.contactUsTel)
        this.$store.commit('setting/setContactUsTime', res.retdata.contactUsTime)
        this.$store.commit('setting/setAdminWebSite', res.retdata.adminWebSite)
        this.$store.commit('setting/setTechnologyConsultingPhone',
          res.retdata.technologyConsultingPhone)
        this.$store.commit('setting/setMediaCooperation', res.retdata.mediaCooperation)
        this.$store.commit('setting/setEmail', res.retdata.email)
        this.$store.commit('setting/setDefaultAvatar', res.retdata.defaultAvatar)
        this.$store.commit('setting/setPostcode', res.retdata.postcode)
        this.$store.commit('setting/setContactUsername', res.retdata.contactUsername)
        this.$store.commit('setting/setCompanyAddress', res.retdata.companyAddress)
        this.$store.commit('setting/setOfficialSite', res.retdata.officialSite)
        this.$store.commit('setting/setBusinessQrCode',
          res.retdata.businessQrCode)
        this.$store.commit('setting/setHelloWord', res.retdata.helloWord)
        this.$store.commit('setting/setFriendLinks', res.retdata.friendLinks)
        this.$store.commit('setting/setadvertises', res.retdata.advertises)
      });
    }
  },
  created() {
  },
  beforeMount() {
    this.setStoreSettingModule();
  }
}
</script>

<style lang="scss">
@import '~element-ui/lib/theme-chalk/index.css';
@import "resources/css/font-awesome-4.7.0/css/font-awesome.min.css";
@import "resources/css/common";
@import "resources/css/main.css";
</style>
