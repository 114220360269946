export default [

  {
    path: '/',
    name: 'home',
    component: () => import('@PAGE/home/Index'),
    redirect: '/',
    children: [
      {
        path: '/login',
        name: '登录页',
        component: () => import('@PAGE/account/login/UserLogin')
      },
      {
        path: '/register',
        name: '注册页',
        component: () => import('@PAGE/account/register/UserRegister')
      },
      {
        path: '/',
        name: '首页',
        component: () => import('@PAGE/Index')
      },
      {
        path: '/shop',
        name: '我的店铺',
        component: () => import('@PAGE/shop-manage/ShopIndexMain'),
        redirect: '/shop/index',
        meta: {
          requireAuth: true
        },
        children: [
          {
            path: '/shop/index',
            name: '我的店铺-首页',
            component: () => import('@PAGE/shop-manage/ShopAuthIndex'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/shop/statistics',
            name: '我的店铺-统计',
            component: () => import('@PAGE/shop-manage/ShopStatistics'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/shop/release-production',
            name: '发布',
            component: () => import('@PAGE/home/ReleaseProduction'),
            meta: {
              requireAuth: true,
            }
          },
          {
            path: '/shop/sumeddata',
            name: '概括',
            component: () => import('@PAGE/shop-manage/ShopSummedData'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/shopinfo',
            name: '完善信息',
            component: () => import('@PAGE/shop-manage/ShopInfoUpdate'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/shop/sale/sourcecode',
            name: '商品管理 ',
            component: () => import('@PAGE/shop-manage/production-manage/sourcecode/Index'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/shop/sale/techdocs',
            name: '文档管理 ',
            component: () => import('@PAGE/shop-manage/production-manage/techdocs/Index'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/shop/sale/design',
            name: '设计管理 ',
            component: () => import('@PAGE/shop-manage/production-manage/design/Index'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/shop/sale/service',
            name: '我的服务',
            component: () => import('@PAGE/shop-manage/service-manage/Index'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/shop/release/serivce',
            name: '发布服务',
            component: () => import('@PAGE/shop-manage/service-manage/ServiceEdit'),
            meta: {
              requireShopRegister: true,
              requireAuth: true,
            }
          },
          {
            path: '/shop/release/techdocs/edit',
            name: '发布文档',
            component: () => import('@PAGE/shop-manage/production-manage/techdocs/TechDocsEdit'),
            meta: {
              requireShopRegister: true,
              requireAuth: true,
            }
          },
          {
            path: '/shop/release/techdocs/edit/:id(\\d+)',
            name: '编辑文档',
            component: () => import('@PAGE/shop-manage/production-manage/techdocs/TechDocsEdit'),
            props: (route) => {
              return {
                id: parseInt(route.params.id) || 0
              }
            },
            meta: {
              requireShopRegister: true,
              requireAuth: true,
            }
          },
          {
            path: '/shop/release/design/edit',
            name: '发布设计',
            component: () => import('@PAGE/shop-manage/production-manage/design/DesignEdit'),
            meta: {
              requireShopRegister: true,
              requireAuth: true,
            }
          },
          {
            path: '/shop/release/design/edit/:id(\\d+)',
            name: '编辑设计',
            component: () => import('@PAGE/shop-manage/production-manage/design/DesignEdit'),
            props: (route) => {
              return {
                id: parseInt(route.params.id) || 0
              }
            },
            meta: {
              requireShopRegister: true,
              requireAuth: true,
            }
          },
          {
            path: '/shop/release/serivce/:id(\\d+)',
            name: '编辑服务',
            props: (route) => {
              return {
                id: parseInt(route.params.id) || 0
              }
            },
            component: () => import('@PAGE/shop-manage/service-manage/ServiceEdit'),
            meta: {
              requireShopRegister: true,
              requireAuth: true,
            }
          },
          {
            path: '/product/productCategory',
            name: '跳转发布源码页面-选择商品分类',
            redirect: '/shop/release/sourcecode',
            meta: {
              requireShopRegister: true,
              requireAuth: true,
            }
          },
          {//release/sourcecode/
            path: '/shop/release/:type',
            name: '添加商品-选择分类',
            props: (route) => {
              return {
                type:route.params.type || ''
              }
            },
            component: () => import('@PAGE/shop-manage/production-manage/sourcecode/ReleaseSourceCode'),
            meta: {
              requireShopRegister: true,
              requireAuth: true,
            }
          },
          {
            path: '/shop/release/sourcecode/edit',
            name: '发布源码',
            component: () => import('@PAGE/shop-manage/production-manage/sourcecode/SourceCodeEdit'),
            meta: {
              requireShopRegister: true,
              requireAuth: true,
            }
          },
          {
            path: '/shop/transaction',
            name: '交易',
            component: () => import('@PAGE/shop-manage/transaction-manager/TransactionOrder'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/shop/service-order',
            name: '服务订单',
            component: () => import('@PAGE/shop-manage/transaction-manager/ServiceOrder'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/business/intentioncustomer',
            name: '意向客户',
            props: true,
            component: () => import('@PAGE/shop-manage/business-manage/IntentionCustomer'),
            meta: {
              requireAuth: true
            }
          },
        ]
      },
      {
        path: '/order/orderDetail/:id(\\d+)',
        name: '订单详情',
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        },
        component: () => import('@PAGE/order/OrderDetail'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/order/orderDetail4Shop/:id(\\d+)',
        name: '订单详情4Shop',
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        },
        component: () => import('@PAGE/order/Detail4Shop'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/order/service/orderDetail/:id(\\d+)',
        name: '订单详情Service',
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        },
        component: () => import('@PAGE/order/ServiceOrderDetail'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/order/service/orderDetail4Shop/:id(\\d+)',
        name: '订单详情',
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        },
        component: () => import('@PAGE/shop-manage/transaction-manager/ServiceOrderDetail4'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/evaluation/:type(SOURCECODE|DESIGN|DOCUMENT)/:id(\\d+)',
        name: '商品评价',
        props: (route) => {
          return {
            id: parseInt(route.params.id),
            type: route.params.type,
          }
        },
        component: () => import('@PAGE/shop-manage/production-manage/production-commnet/CommentMain'),
        meta: {}
      },
      {
        path: '/order/salesAfter/:id(\\d+)',
        name: '售后申请',
        props: (route) => {
          return {
            id: parseInt(route.params.id)
          }
        },
        component: () => import('@PAGE/order/SalesAfter'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/order/salesAfterRequest/:id(\\d+)',
        name: '售后申请',
        props: (route) => {
          return {
            id: parseInt(route.params.id)
          }
        },
        component: () => import('@PAGE/order/SalesAfterRequest'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/service-provider/view/:id(\\d+)',
        name: '店铺首页',
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        },
        component: () => import('@PAGE/home-center/serviceprovider/ProviderDetails'),
        children: []
      },
      {
        path: '/shopping-cart',
        name: '购物车',
        component: () => import('@PAGE/shopping-cart/ShoppingCartIndex'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/user/qqcodeaudit',
        name: 'QQ验证页',
        component: () => import('@PAGE/account/QqCodeAudit'),
        props: true
      },
      {
        path: '/user-center',
        name: '个人中心',
        component: () => import('@PAGE/users-center-manage/Index'),
        redirect: '/account/userbaseinfo',
        meta: {
          requireAuth: true
        },
        children: [
          {
            path: '/account/userbaseinfo',
            name: '个人中心-首页',
            component: () => import('@PAGE/users-center-manage/user-manage/user/UserInfoMain'),
            redirect: '/account/userbaseinfo',
            meta: {
              requireAuth: true
            },
            children: [
              {
                path: '/account/userbaseinfo',
                component: () => import('@PAGE/users-center-manage/user-manage/user/BaseInfo'),
                name: '基本信息',
                meta: {
                  requireAuth: true
                }
              },
              {
                path: '/user/userinfo/avatar',
                name: '用户头像',
                component: () => import('@PAGE/users-center-manage/user-manage/user/UserAvatar'),
                meta: {
                  requireAuth: true
                }
              },
              {
                path: '/user/userinfo/bindPhone',
                name: '绑定手机',
                component: () => import('@PAGE/users-center-manage/user-manage/user/BindPhone'),
                meta: {
                  requireAuth: true
                }
              },
              {
                path: '/user/userinfo/verified',
                name: '实名认证',
                component: () => import('@PAGE/users-center-manage/user-manage/user/PersonVerified'),
                meta: {
                  requireAuth: true
                }
              },
              {
                path: '/user/userinfo/businessverified',
                name: '企业实名认证',
                component: () => import('@PAGE/users-center-manage/user-manage/user/EnterpriseVerified'),
                meta: {
                  requireAuth: true
                }
              },
              {
                path: '/user/userinfo/bindEmail',
                name: '绑定邮箱',
                component: () => import('@PAGE/users-center-manage/user-manage/user/BindEmail'),
                meta: {
                  requireAuth: true
                }
              },
              {
                path: '/user/userinfo/address',
                name: '收货地址',
                component: () => import('@PAGE/users-center-manage/user-manage/user/Address'),
                meta: {
                  requireAuth: true
                }
              }
            ]
          },
          {
            path: '/account/cashflow',
            name: '资金管理',
            component: () => import('@PAGE/users-center-manage/user-manage/MoneyManage'),
            meta: {
              requireAuth: true
            },
          },
          {
            path: '/account/riskwarning',
            name: '交易风险提示',
            component: () => import('@PAGE/users-center-manage/user-manage/RiskWarning'),
            meta: {
              requireAuth: true
            },
          },
          {
            path: '/account/cashdeposit',
            name: '保证金管理',
            component: () => import('@PAGE/users-center-manage/user-manage/MyMargin'),
            meta: {
              requireAuth: true
            },
          },
          {
            path: '/user/mymargin/record',
            name: '保证金记录',
            component: () => import('@PAGE/users-center-manage/user-manage/MyMarginRecord'),
            meta: {
              requireAuth: true
            },
          },
          {
            path: '/user/account',
            name: '账号管理',
            component: () => import('@PAGE/users-center-manage/user-manage/account/AccountMain'),
            redirect: '/account/rechagre',
            meta: {
              requireAuth: true
            },
            children: [
              {
                path: '/account/rechagre',
                name: '快速充值',
                component: () => import('@PAGE/users-center-manage/user-manage/account/AccountRecharge')
              },
              {
                path: '/user/account/withdraw',
                name: '我要提现',
                component: () => import('@PAGE/users-center-manage/user-manage/account/AccountWithdrawal')
              },
              {
                path: '/user/account/paypwd',
                name: '支付密码',
                component: () => import('@PAGE/users-center-manage/user-manage/account/ChangePaypwd')
              },
              {
                path: '/user/account/collectionCode',
                name: '收款码',
                component: () => import('@PAGE/users-center-manage/user-manage/account/PaymentCode')
              },
              {
                path: '/user/account/password',
                name: '登录密码',
                component: () => import('@PAGE/users-center-manage/user-manage/account/ChangeAccountPwd')
              },
              {
                path: '/user/account/withdraw_record',
                name: '提现记录',
                component: () => import('@PAGE/users-center-manage/user-manage/account/WithdrawalRecord')
              },

            ]
          },
          {
            path: '/requirement/myrelease',
            name: '我发布的需求',
            component: () => import('@PAGE/users-center-manage/requirement-manage/MyRequirement'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/requirement/myjoining',
            name: '我参与的需求',
            component: () => import('@PAGE/users-center-manage/requirement-manage/JoinRequirement'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/user/order',
            name: '我的订单',
            component: () => import('@PAGE/users-center-manage/order-manage/OrderIndex'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/user/service/order',
            name: '服务订单',
            component: () => import('@PAGE/users-center-manage/order-manage/ServiceOrder'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/order/refund/:id(\\d+)',
            name: '我的退款订单',
            component: () => import('@PAGE/order/ApplyRefund'),
            props: (route) => {
              return {
                id: parseInt(route.params.id) || 0
              }
            },
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/user/favorite',
            name: '我的收藏',
            component: () => import('@PAGE/users-center-manage/order-manage/MyFavorite'),
            redirect: '/user/favorite/shop',
            meta: {
              requireAuth: true
            },
            children: [
              {
                path: '/user/favorite/shop',
                name: '店铺收藏',
                component: () => import('@PAGE/users-center-manage/order-manage/favorite/Shop'),
                meta: {
                  requireAuth: true
                }
              },
              {
                path: '/user/favorite/require',
                name: '需求收藏',
                component: () => import('@PAGE/users-center-manage/order-manage/favorite/Requirement.vue'),
                meta: {
                  requireAuth: true
                }
              },
              {
                path: '/user/favorite/product',
                name: '源码收藏',
                component: () => import('@PAGE/users-center-manage/order-manage/favorite/Product'),
                meta: {
                  requireAuth: true
                }
              },
              {
                path: '/user/favorite/part-time',
                name: '兼职收藏',
                component: () => import('@PAGE/users-center-manage/order-manage/favorite/PartTime'),
                meta: {
                  requireAuth: true
                }
              },
              {
                path: '/user/favorite/design',
                name: '设计收藏',
                component: () => import('@PAGE/users-center-manage/order-manage/favorite/Design'),
                meta: {
                  requireAuth: true
                }
              },
              {
                path: '/user/favorite/service',
                name: '服务收藏',
                component: () => import('@PAGE/users-center-manage/order-manage/favorite/Service'),
                meta: {
                  requireAuth: true
                }
              },
              {
                path: '/user/favorite/doc',
                name: '文档收藏',
                component: () => import('@PAGE/users-center-manage/order-manage/favorite/TechDocs'),
                meta: {
                  requireAuth: true
                }
              }
            ]
          },
          {
            path: '/user/address',
            name: '收货地址 ',
            component: () => import('@PAGE/users-center-manage/user-manage/user/Address'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/user/job',
            name: '发布兼职',
            component: () => import('@PAGE/users-center-manage/parttime-manage/ReleaseJobRequire'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/user/jobedit',
            name: '兼职发布',
            component: () => import('@PAGE/users-center-manage/parttime-manage/ParttimeJobEdit'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/user/jobedit/:id(\\d+)',
            name: '兼职修改',
            props: (route) => {
              return {
                id: parseInt(route.params.id) || 0
              }
            },
            component: () => import('@PAGE/users-center-manage/parttime-manage/ParttimeJobEdit'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/user/works',
            name: '作品集',
            component: () => import('@PAGE/users-center-manage/parttime-manage/MyProduction'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/user/addoreditworks',
            name: '添加作品集',
            component: () => import('@PAGE/users-center-manage/parttime-manage/AddProduction'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/user/addoreditworks/:id(\\d+)',
            name: '修改作品集',
            props: (route) => {
              return {
                id: parseInt(route.params.id) || 0
              }
            },
            component: () => import('@PAGE/users-center-manage/parttime-manage/AddProduction'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/user/resume',
            name: '个人履历',
            component: () => import('@PAGE/users-center-manage/parttime-manage/PersonResumeMain.vue'),
            redirect: '/user/resume/work-experience',
            meta: {
              requireAuth: true
            },
            children: [{
              path: '/user/resume/work-experience',
              name: '工作经历',
              component: () => import('@PAGE/users-center-manage/parttime-manage/PersonResume-WorkExperience.vue'),
              meta: {
                requireAuth: true
              }
            }, {
              path: '/user/resume/ed-experience',
              name: '教育经历',
              component: () => import('@PAGE/users-center-manage/parttime-manage/PersonResume-EduExperience.vue'),
              meta: {
                requireAuth: true
              }
            }, {
              path: '/user/resume/awards-experience',
              name: '获奖经历',
              component: () => import('@PAGE/users-center-manage/parttime-manage/PersonResume-AwardsExperience.vue'),
              meta: {
                requireAuth: true
              }
            }]
          }
        ]
      },
      {
        path: '/product',
        name: '商品管理',
        component: () => import('@PAGE/shop-manage/production-manage/sourcecode/Index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/sourcecode/view/:id(\\d+)',
        name: '商品预览',
        component: () => import('@PAGE/shop-manage/production-manage/sourcecode/SourceCodeView'),
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        }
      },
      {
        path: '/techdocs/view/:id(\\d+)',
        name: '文档详情',
        component: () => import('@PAGE/shop-manage/production-manage/techdocs/TechDocsView'),
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        }
      },
      {
        path: '/design/view/:id(\\d+)',
        name: '设计详情',
        component: () => import('@PAGE/shop-manage/production-manage/design/DesignView'),
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        }
      },
      {
        path: '/order/evaluate/:id(\\d+)',
        name: '订单评价',
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        },
        component: () => import('@PAGE/order/UserEvaluate'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/submit-requirement',
        name: '发布需求',
        component: () => import('@PAGE/home/ReleaseRequirement'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/center/techservice',
        name: '服务中心',
        component: () => import('@PAGE/home-center/techservice/TechServiceCenter'),
        meta: {}
      },
      {
        path: '/center/parttime',
        name: '兼职中心',
        component: () => import('@PAGE/home-center/parttimeuser/ParttimeUserCenter'),
        meta: {}
      },
      {
        path: '/center/techdocs',
        name: '文档中心',
        component: () => import('@PAGE/home-center/techdocs/TechDocsCenter'),
        meta: {}
      },
      {
        path: '/center/design',
        name: '设计中心',
        component: () => import('@PAGE/home-center/design/DesignCenter'),
        meta: {}
      },
      {
        path: '/parttime/view/:id(\\d+)',
        name: '兼职详情',
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        },
        component: () => import('@PAGE/users-center-manage/parttime-manage/ParttimeDetail'),
        meta: {}
      },
      {
        path: '/service/view/:id(\\d+)',
        name: '服务详情',
        component: () => import('@PAGE/shop-manage/service-manage/ServiceView'),
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        }
      },
      {
        path: '/service/buy',
        name: '购买服务',
        redirect: '/404',
        component: () => import('@PAGE/shop-manage/service-manage/buy'),
        props: true,
        meta: {
          requireAuth: true
        },
        children: [
          {
            path: '/service/buy/confirmOrder',
            component: () => import('@PAGE/home-center/serviceprovider/BuyService.vue'),
            name: '服务确认订单'
          },
          {
            path: '/service/buy/signingContract',
            component: () => import('@PAGE/home-center/serviceprovider/SigningContract'),
            name: '服务签订合同'
          },
          {
            path: '/service/buy/confirmService',
            component: () => import('@PAGE/home-center/serviceprovider/ConfirmService'),
            name: '确认收到服务'
          },
          {
            path: '/service/buy/pay',
            component: () => import('@PAGE/home-center/serviceprovider/ConfirmPay.vue'),
            name: '确认服务支付'
          },
          {
            path: '/service/buy/evaluate',
            component: () => import('@PAGE/home-center/serviceprovider/Evaluate'),
            name: '服务评价'
          },
        ],
      },
      {
        path: '/center/service-provider',
        name: '服务商中心',
        component: () => import('@PAGE/home-center/serviceprovider/ServiceProviderCenter'),
        meta: {}
      },
      {
        path: '/submit-requirement/:id(\\d+)',
        name: '编辑需求',
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        },
        component: () => import('@PAGE/home/ReleaseRequirement'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/requirement/view/:id(\\d+)',
        name: '需求预览',
        component: () => import('@PAGE/users-center-manage/requirement-manage/RequirementView'),
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        }
      },
      {
        path: '/center/sourcecode',
        name: '源码大厅',
        props: true,
        component: () => import('@PAGE/home-center/sourcecode/SourceCodeCenter'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: '/message',
        name: '消息中心',
        props: true,
        component: () => import('@PAGE/home/MessageNotify'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/center/requirement',
        name: '需求中心',
        props: true,
        component: () => import('@PAGE/home-center/requirement/RequirementCenter'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: '/feedback',
        name: '意见反馈',
        props: true,
        component: () => import('@PAGE/home/UserFeedback'),
        meta: {}
      },
      {
        path: '/protocol/:index',
        name: '协议',
        props: true,
        component: () => import('@PAGE/home/Protocol.vue')
      },
      {
        path: '/systemNotice/:id(\\d+)',
        name: '系统公告',
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        },
        component: () => import('@PAGE/common/SystemNotice')
      },
    ]
  },
  {
    path: '/order',
    name: '购买商品',
    redirect: '/shopping-cart',
    component: () => import('@PAGE/order/ConfirmOrderMain'),
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: '/order/buy/',
        redirect: '/shopping-cart'
      },
      {
        path: '/order/buy/:json',
        name: '商品确认订单',
        props: true,
        component: () => import('@PAGE/order/ConfirmOrder'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/order/pay/:id',
        name: '订单支付',
        props: (route) => {
          return {
            id: route.params.id
          }
        },
        component: () => import('@PAGE/order/OrderPay'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/order/payResult',
        name: '支付结果',
        props: true,
        component: () => import('@PAGE/order/OrderPayResult'),
        meta: {
          requireAuth: true
        }
      }
    ]
  },
  {
    path: '/limitAccess',
    name: '限制访问',
    component: () => import('@PAGE/common/IpLimitAccess')
  },
  {
    path: '/help-center',
    name: '帮助中心',
    props: true,
    component: () => import('@PAGE/home/HelpCenter')
  },
  {
    path: '/',
    name: 'CommonLayout',
    component: () => import('@PAGE/account/appeal/AppealMain'),
    redirect: '/',
    children: [
      {
        path: '/loginRestrictions/:id(\\d+)',
        name: '登录限制',
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        },
        component: () => import('@PAGE/common/LoginRestrictions')
      },
      {
        path: '/loginRestrictionsHelp/:id(\\d+)',
        name: '登录限制帮助',
        props: (route) => {
          return {
            id: parseInt(route.params.id) || 0
          }
        },
        component: () => import('@PAGE/common/LoginRestrictionsHelp')
      },
      {
        path: '/forget-pwd',
        name: '忘记密码',
        redirect: '/forget-pwd/phone',
        component: () => import('@PAGE/account/login/ForgetPwd'),
        children: [
          {
            path: '/forget-pwd/phone',
            name: '通过手机号找回密码',
            component: () => import('@CMP/forgetpwd/ForgetPwdByPhone'),
          },
          {
            path: '/forget-pwd/email',
            name: '通过邮箱找回密码',
            component: () => import('@CMP/forgetpwd/ForgetPwdByEmail'),
          },
        ]
      },
      {
        path: '/account-appeal',
        name: '账号申诉',
        component: () => import('@PAGE/account/appeal/Index'),
        children: [{
          path: '/account-appeal/submit',
          name: '账号申诉-首页',
          component: () => import('@PAGE/account/appeal/Apply')
        }, {
          path: '/account-appeal/query',
          name: '申诉结果查询',
          component: () => import('@PAGE/account/appeal/Result')
        }, {
          path: '/account-appeal/reset',
          name: '重置资料',
          component: () => import('@PAGE/account/appeal/Reset')
        }, {
          path: '/account-appeal/help',
          name: '申诉帮助',
          component: () => import('@PAGE/account/appeal/Helps')
        }]
      }]
  },
  {
    path: '*',
    name: '404',
    component: () => import('@PAGE/common/error/404'),
    children: []
  }
]
