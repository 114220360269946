import axios from '../resources/js/request'

const request = axios.request;

export default {
  /**
   * 浏览历史记录
   * @param userId 用户ID 允许为null
   * @param id 目标ID
   * @param title 目标标题
   * @param type 1、需求 2、源码 3设计 4 服务 5文档 6兼职
   * @returns {Promise}
   */
  viewHistory({
    userId, id, title, type
  }) {
    return request.doPost({
      reqcode: 1161,
      reqdesc: '足迹',
      userId: userId,
      targetId: id,
      targetTitle: title,
      type: type,
    })
  },
  AdsviewHistory({
    userId, id
  }) {
    return request.doPost({
      reqcode: 1200,
      reqdesc: '查看广告记录',
      userId: userId,
      targetId: id,
    })
  },
  /**
   * 1.店铺 2 需求 3 源码 4 设计 5 服务 6 文档 7 兼职'
   * @param userId
   * @param id
   * @param type
   * @returns {*}
   */
  contactClickHistory({userId, id, type}) {
    return request.doPost({
      reqcode: 1195,
      reqdesc: 'click record',
      userId, id, type
    })
  },
  loadConfigIndustry() {
    return request.doPost({
      reqcode: 1214,
      reqdesc: 'config'
    });
  },
  loadAuditHistory({type, id}) {
    return request.doPost({
      reqcode: '1061',
      reqdesc: '审核历史',
      id, type,
    })
  },
  loadImage({type}) {
    return request.doPost({
      reqcode: '1063',
      reqdesc: '首页轮播图',
      btype: type,
      invalid_cache: new Date().getTime(),
    });
  },
  loadProtocol({protocolName}) {
    return request.doPost({
      reqcode: '1080',
      reqdesc: '协议',
      agreementName: protocolName,
      invalid_cache: new Date().getTime(),
    });
  },
  loadSystemNotice({page, limit}) {
    return request.doPost({
      reqcode: 1206,
      reqdesc: 'load system notice',
      page, limit,
    });
  },
  loadSystemNoticeDetail({id}) {
    return request.doPost({
      reqcode: 1207,
      reqdesc: 'system notice detail',
      id,
    })
  },
  loginRestrictionsDetail({id}) {
    return request.doPost({
      reqcode: 1220,
      reqdesc: 'detail',
      id,
    })
  },
  profitRateList() {
    return request.doPost({
      reqdesc: 'profit rate list',
      reqcode: 1222
    });
  },
}
