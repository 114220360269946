<template>
  <div>
    <div class="container">
      <div class="sctp-bg-white sctp-pad15 sctp-mar-b15 sctp-flex subMenu-wrap">
        <template v-for="(menu,index) in menus">
          <div :key="index" :id="menu.name" @click="onClickRouter(menu.name)">
            <router-link :class="{'router-link-exact-active' : isActive && menu.url === '#' }"
                         :to="menu.url">
              {{ menu.name }}
            </router-link>
          </div>
        </template>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'userinfo',
  components: {},
  data() {
    return {
      menus: [
        {name: '基本资料', url: '/account/userbaseinfo'},
        {name: '用户头像', url: '/user/userInfo/avatar'},
        {name: '手机绑定', url: '/user/userInfo/bindPhone'},
        {name: '邮箱认证', url: '/user/userInfo/bindEmail'},
        {name: '实名认证', url: '#'},
        {name: '收货地址', url: '/user/userInfo/address'}
      ]
    }
  },
  methods: {
    onClickRouter(name) {
      if (name === '实名认证') {
        this.$request.post({
          reqcode: '1054',
          reqdesc: '实名认证状态',
          userId: this.user.userId,
          invalid_cache: new Date().getTime(), // 勿删
        }).then(res => {
          const {retdata} = res
          const account_type = retdata.account_type
          let path;
          if ([2, 3, 4].includes(account_type)) {
            path = '/user/userinfo/businessverified'
          } else {
            path = '/user/userinfo/verified'
          }
          this.$router.push({path: path})
          // this.menus[4].url = path
        })
      }
    }
  },
  beforeMount() {
  },
  beforeCreate() {
  },
  computed: {
    isActive() {
      let urls = [
        '/user/userinfo/businessverified',
        '/user/userinfo/verified'
      ];
      return urls.includes(this.$route.path)
    }
  }
}
</script>

<style scoped>
/*@formatter:off*/
  .container .subMenu-wrap {height: 49px;font-size: 15px;font-weight: 700;}
  .container .subMenu-wrap > div {border-right: 1px solid #e5e5e5;padding: 0 25px;}
  .container .router-link-active {color: #FF9900!important;}
  /*@formatter:on*/
</style>
