import axios from '../resources/js/request'

const request = axios.request

export default {
  /**
   * 获取钱包余额
   * @param userId
   * @returns {*}
   */
  getWallet({userId}) {
    return request.doPost({
      reqcode: '1084',
      reqdesc: '钱包余额',
      userId: userId,
      t: new Date().getTime(),
    })
  },
  /**
   * 提交提现申请
   */
  applyForWithdrawal(
    {userId, amount, way, account, phone, username}
  ) {
    return request.doPost({
      reqcode: '1147',
      reqdesc: '钱包余额',
      userId: userId,
      amount: amount,
      way: way,
      account: account,
      phone: phone,
      username: username,
    })
  },
  /**
   * 提现记录
   */
  withdrawRecord({userId, page, limit}) {
    return request.doPost({
      reqcode: 1148,
      reqdesc: '提现申请记录',
      userId: userId,
      page: page,
      limit: limit
    })
  },
  cancelWithdraw({id, userId}) {
    return request.doPost({
      reqcode: '1215',
      reqdesc: 'cancel withdraw',
      id, userId,
    })
  },
  checkPwd({userId, password}) {
    return request.doPost({
      reqcode: 1168,
      reqdesc: '校验密码',
      userId: userId,
      password,
    });
  },
  checkWhetherWithdrawAble({userId, amount}) {
    return request.doPost({
      reqcode: 1208,
      reqdesc: 'checkWhetherWithdrawAble',
      userId, amount,
    })
  }
}
