import axios from '../resources/js/request'

const request = axios.request;

export default {
  sendPhoneCode({phone, checkExist = false, checkNotExist = false}) {
    return request.doPost({
      reqcode: '1038',
      reqdesc: '发送验证码',
      phone: phone,
      checkExist,
      checkNotExist,
    });
  },
  sendEmailCode({email, checkExist = false, type, userId}) {
    return request.doPost({
      reqcode: 1051,
      reqdesc: '发送邮箱验证码',
      email: email,
      checkExist: checkExist,
      type, userId,
    });
  },
  checkPhoneCode({phone, code}) {
    return request.doPost({
      reqcode: '1099',
      reqdesc: '校验验证码',
      type: '1',
      number: phone,
      captcha: code
    });
  },
  checkEmailCode({email, code}) {
    return request.doPost({
      reqcode: '1099',
      reqdesc: '校验验证码',
      type: '2',
      number: email,
      captcha: code
    });
  },
}
