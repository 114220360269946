import axios from '../resources/js/request'

const request = axios.request;

export default {
  detail({id, userId}) {
    return request.doPost({
      reqcode: '1069',
      reqdesc: '需求详情',
      requireid: id,
      userid: userId
    });
  }, requireUpdate({id, userId}) {
    return request.doPost({
      reqcode: '1230',
      reqdesc: '需求编辑',
      requireid: id,
      userid: userId
    });
  },
  incrementViews({id, userId}) {
    return request.doPost({
      reqcode: '1070',
      reqdesc: '增加点击数',
      requireid: id,
      userid: userId
    });
  },
  condition() {
    return request.doPost({
      reqcode: '1033',
      reqdesc: '源码类型'
    });
  },
  recommendForRequire({requireId}) {
    return request.doPost({
      reqcode: '1187',
      reqdesc: '相似推荐',
      requireId,
    });
  },
  loadRecommendMeData({userId, requireId, reqFlag, page, limit}) {
    return request.doPost({
      reqcode: 1218,
      reqdesc: 'recommend me',
      userId, reqFlag, page, limit, requireId,
    })
  },
  submitRecommendMeData({userId, reqFlag, value, requireId}) {
    return request.doPost({
      reqcode: 1219,
      reqdesc: 'save recommend me',
      userId, reqFlag, value, requireId,
    })
  },
  addAttachment({id, path}) {
    return request.doPost({
      reqcode: 1223,
      reqdesc: 'add attachment',
      id, path,
    })
  },
  removeAttachment({id}) {
    return request.doPost({
      reqcode: 1225,
      reqdesc: 'remove attachment',
      id,
    })
  },
  userCodeRequireList({page, limit, userId, reqFlag, requireId, auditFlag, createStart, createEnd}) {
    return request.doPost({
      reqcode: 1053,
      reqdesc: 'code require list',
      page, limit, userId, createStart, createEnd, auditFlag, requireId,
      reqflag: reqFlag
    });
  },
  codeRequireCenter({page, limit, orderBy, userId, minPrice, maxPrice, reqFlag, title, reqTypeId}) {
    return request.doPost({
      reqcode: '1031',
      reqdesc: '需求列表',
      page,
      limit,
      orderBy,
      userId,
      minPrice,
      maxPrice,
      reqFlag,
      title,
      reqTypeId,
    })
  },
  serviceTender({userId, price, day, viewer, mark, requireId,}) {
    return request.doPost({
      reqcode: '1268',
      reqdesc: 'service tender',
      userId, price, day, viewer, mark, requireId,
    })
  },
  tenderList({page, limit, requireId, userId}) {
    return request.doPost({
      reqcode: '1269',
      reqdesc: 'tender list',
      page, limit, requireId, userId
    })
  }
}
