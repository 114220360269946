import axios from '../resources/js/request'

const request = axios.request;

export default {
  /**
   * @param payWay
   * @param userId
   * @param shopId
   * @param eId
   * @param eType
   * @returns {*}
   */
  viewContactInformation({payWay, userId, shopId, eId, eType}) {
    return request.doPost({
      reqcode: '1065',
      reqdesc: '查看手机号',
      userId: userId,
      shopId: shopId,
      payWay: {
        1: 'ALIPAY',
        2: 'WECHAT',
        3: 'WALLET',
      }[payWay],
      eid: eId,
      productType: {
        1: 'SHOP',
        2: 'SOURCECODE',
        3: 'DEMAND',
        4: 'DESIGN',
        5: 'SERVICE',
        6: 'DOCUMENT',
        7: 'PARTTIME',
        8: 'CUSTOMER',
      }[eType]
    });
  },
  shopDetail({userId}) {
    return request.doPost({
      reqcode: '1083',
      reqdesc: '店铺信息',
      userId,
    });
  },
  submitShopInfo({name, logo, area, address, userId, principal, tel, qq, wx, wxQrcode, introduce, mainBusiness}) {
    return request.doPost({
      reqcode: '1004',
      reqdesc: '提交店铺信息',
      name,
      logo,
      area,
      address,
      userId,
      principal,
      tel,
      introduce,
      mainBusiness,
      qq,
      wx,
      wxQrcode
    });
  },
  showInfoCondition() {
    return request.doPost({
      reqdesc: 'condition',
      reqcode: 1217
    });
  },
  sourceCode({page, limit, shopId, userId, keyword}) {
    return request.doPost({
      reqcode: 1245,
      reqdesc: 'shop source code',
      page, limit, shopId, userId, keyword,
    })
  },
  service({page, limit, shopId, userId, keyword}) {
    return request.doPost({
      reqcode: 1246,
      reqdesc: 'shop service code',
      page, limit, shopId, userId, keyword,
    })
  },
  design({page, limit, shopId, userId, keyword}) {
    return request.doPost({
      reqcode: 1247,
      reqdesc: 'shop design code',
      page, limit, shopId, userId, keyword,
    })
  },
  document({page, limit, shopId, userId, keyword}) {
    return request.doPost({
      reqcode: 1248,
      reqdesc: 'shop document code',
      page, limit, shopId, userId, keyword,
    })
  },
  /**
   * 检查该用户是否入驻店铺
   * @param userId 当前用户
   */
  checkShopIsRegister({userId}) {
    return request.doPost({
      reqcode: 1253,
      reqdesc: 'check register',
      userId,
    });
  },
  authenticationStatus({userId}) {
    return request.doPost({
      reqcode: '1009',
      reqdesc: '店铺激活状态',
      userId: userId,
    })
  },
  shopStatistics({userId}) {
    return request.doPost({
      reqcode: '1003',
      reqdesc: '店铺统计',
      userId: userId,
    })
  }
}
