import axios from '../resources/js/request'

const request = axios.request;

export default {
  loadCondition() {
    return request.doPost({
      reqcode: 1158,
      reqdesc: '文档候选条件',
    });
  },
  persist(
    {
      codeType, docId, docType, docSubType, docName, docFormat,
      encrypt, editable, docPage, docNumber,
      docSize, includeWatermark, docCover,
      showImages, price, originPrice, stock,
      transactionForm, deliveryForm, introduction,
      userId, sizeUnit, encryptPassword, netdiscName,
      netdiscUrl, netdiscPwd, netdiscUnzipPwd, docPath, temporary_storage
    }
  ) {
    return request.doPost({
      reqcode: 1159,
      reqdesc: '提交发布文档',
      userId: userId,
      docId: docId,
      docType: docType,
      docSubType: docSubType,
      docName: docName,
      docFormat: docFormat,
      encrypt: encrypt,
      editable: editable,
      docPage: docPage,
      docNumber: docNumber,
      docSize: docSize,
      includeWatermark: includeWatermark,
      docCover: docCover,
      showImages: showImages,
      price: price,
      originPrice: originPrice,
      stock: stock,
      transactionForm: transactionForm,
      deliveryForm: deliveryForm,
      introduction: introduction,
      sizeUnit: sizeUnit,
      encryptPassword: encryptPassword,
      netdiscUrl, netdiscPwd, netdiscUnzipPwd, docPath, codeType, temporary_storage, netdiscName
    });
  }
  ,
  list({page, limit, userId, id, docName, priceMin, priceMax}) {
    return request.doPost({
      reqcode: 1163,
      reqdesc: '文档管理',
      page: page,
      limit: limit,
      userId: userId,
      id, docName, priceMin, priceMax,
    })
  },
  detail({docId}) {
    return request.doPost({
      reqcode: 1164,
      reqdesc: '文档详情',
      docId: docId,
    });
  },
  editdetail({docId, userId}) {
    return request.doPost({
      reqcode: 1232,
      reqdesc: '文档编辑详情',
      docId: docId,
      userId: userId
    });
  },
  viewDetail({docId, userId}) {
    return request.doPost({
      reqcode: 1166,
      reqdesc: '文档详情',
      docId: docId,
      userId: userId,
    });
  },
  upperPay({docId, userId, payWay, date}) {
    return request.doPost({
      reqcode: 1169,
      reqdesc: '文档上架支付',
      docId: docId,
      userId: userId,
      payWay: payWay,
      date,
    })
  },
  changeStatus({docId, status, userId}) {
    return request.doPost({
      reqcode: 1175,
      reqdesc: '文档上下架',
      docId: docId,
      status: status, userId
    });
  },
  center({page, limit, docName, orderBy, docType, docSubType, priceMax, priceMin}) {
    return request.doPost({
      reqcode: 1176,
      reqdesc: '文档中心',
      page, limit, docName, orderBy, docType, docSubType, priceMax, priceMin,
    });
  },
  incrementViews({id, userId}) {
    return request.doPost({
      reqcode: '1185',
      reqdesc: '增加访问量',
      id: id,
      userId: userId
    });
  },
  del({id}) {
    return request.doPost({
      reqcode: 1189,
      reqdesc: '文档删除',
      id,
    })
  }
}
