import axios from '../resources/js/request'

const request = axios.request;

export default {
  getWebSiteTitle() {
    return this.loadConfig({
      key: 'CodeTitle'
    });
  },
  loadConfig({key}) {
    return request.doPost({
      reqcode: 1072,
      reqdesc: 'load config',
      key,
    });
  }
}
