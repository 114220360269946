import axios from '../resources/js/request'

const request = axios.request;

export default {
  /**
   * 修改商品交易方式
   * @param productId
   * @param value
   * @param type 1源码 2文档 3设计
   * @returns {*}
   */

  changeDealModel({productId, value, type}) {
    return request.doPost({
      reqcode: 1152,
      reqdesc: '修改交易方式',
      productId: productId,
      value: value,
      type: type,
    });
  },
  /**
   * 添加购物车
   * @param userId
   * @param goodsId
   * @param amount
   * @param type
   *        SHOP(1,"店铺"),
   *        SOURCECODE(2, "源码"),
   *        DEMAND(3, "需求"),
   *        DESIGN(4,"设计"),
   *        SERVICE(5, "服务"),
   *        DOCUMENT(6, "文档"),
   *        PARTTTIME(7, "兼职"),
   * @returns {*}
   */
  addCart({userId, goodsId, amount, type}) {
    return request.doPost({
      reqcode: '1018',
      reqdesc: '添加购物车',
      userId, goodsId, amount, type,
    });
  },
  getComment({id, userId, type, page, limit}) {
    return request.doPost({
      reqcode: 1085,
      reqdesc: '评价',
      id, userId, type, page, limit,
    })
  },
  getFees({type}) {
    let types = {
      SHOP: 1,
      SOURCECODE: 2,
      DEMAND: 3,
      DESIGN: 4,
      SERVICE: 5,
      DOCUMENT: 6,
      PARTTIME: 7,
    }
    return request.doPost({
      reqcode: 1216,
      reqdesc: 'fee',
      type: types[type],
    })
  },
  updateStock({id, type, stock}) {
    return request.doPost({
      reqcode: 1229,
      reqdesc: 'update stock',
      id, type, stock
    })
  },
  updatePrice({id, type, salePrice, realPrice}) {
    return request.doPost({
      reqcode: 1249,
      reqdesc: 'update price',
      id, type, salePrice, realPrice
    })
  },
  updateJobPrice({expectedSalary, salaryUnit, id}) {
    return request.doPost({
      reqcode: 1250,
      reqdesc: 'update job price',
      expectedSalary, salaryUnit, id
    })
  },
  updateDemandPrice({budgetScope, id, minBudget, maxBudget}) {
    return request.doPost({
      reqcode: 1251,
      reqdesc: 'update demand price',
      budgetScope, id, minBudget, maxBudget,
    })
  },
  del({id}) {
    return request.doPost({
      reqcode: 1041,
      reqdesc: 'delete source code',
      id,
    });
  },
  /**
   * 获取指定商品的相似推荐
   */
  selectRecommendSourceCode({productId, page, limit}) {
    return request.doPost({
      reqcode: 1045,
      reqdesc: 'recommend',
      productType: 'SOURCECODE',
      productId, page, limit,
    })
  },
  selectRecommendDocument({productId, page, limit}) {
    return request.doPost({
      reqcode: 1254,
      reqdesc: 'recommend',
      productType: 'DOCUMENT',
      productId, page, limit,
    })
  },
  publishPageCondition() {
    return request.doPost({
      reqcode: '1010',
      reqdesc: '获取商品分类'
    });
  }
}
