import axios from '../resources/js/request'

const request = axios.request;

export default {
  loadCondition() {
    return request.doPost({
      reqcode: 1177,
      reqdesc: '设计候选条件',
    });
  },
  persist(
    {
      designId, codeType, type, subType, name, tools, number, size, sizeUnit, cover,
      showImages, price, originPrice, copyright, copyrightUrl, stock,
      transactionForm, deliveryForm, introduction, netdiscUrl, userId, netdiscName,
      netdiscPwd, netdiscUnzipPwd, codePath, isContainCopyrightPrice,temporary_storage
    }
  ) {
    return request.doPost({
      reqcode: 1178,
      reqdesc: '提交发布设计',
      designId, codeType, type, subType, name, tools, number, size, sizeUnit, cover,
      showImages, price, originPrice, copyright, copyrightUrl, stock,
      transactionForm, deliveryForm, introduction, netdiscUrl, userId,
      netdiscPwd, netdiscUnzipPwd, codePath, isContainCopyrightPrice, netdiscName,temporary_storage
    })
  },
  detail({id}) {
    return request.doPost({
      reqcode: 1179,
      reqdesc: '详情',
      id,
    })
  },
  editdetail({id,userId}) {
    return request.doPost({
      reqcode: 1233,
      reqdesc: '设计编辑详情',
      id:id,
      userId:userId
    })
  },
  list({page, limit, userId, id, name, priceMax, priceMin}) {
    return request.doPost({
      reqcode: 1180,
      reqdesc: 'list',
      page, limit, userId, id, name, priceMax, priceMin
    })
  },
  changeStatus({id, status, userId}) {
    return request.doPost({
      reqcode: 1181,
      reqdesc: '设计上下架',
      id: id,
      status: status, userId
    });
  },
  upperPay({id, userId, payWay, date}) {
    return request.doPost({
      reqcode: 1182,
      reqdesc: '设计上架支付',
      id: id,
      userId: userId,
      payWay: payWay,
      date,
    })
  },
  viewDetail({id, userId}) {
    return request.doPost({
      reqcode: 1183,
      reqdesc: '设计详情',
      id: id,
      userId: userId,
    });
  },
  center({page, limit, name, orderBy, type, subType, minPrice, maxPrice}) {
    return request.doPost({
      reqcode: 1184,
      reqdesc: '设计中心',
      page, limit, name, orderBy, type, subType, priceMin: minPrice, priceMax: maxPrice,
    });
  },
  del({id}) {
    return request.doPost({
      reqcode: 1188,
      reqdesc: '设计删除',
      id,
    })
  },
  /**
   * 相似推荐
   */
  similarRecommendation({id}) {
    return request.doPost({
      reqcode: 1213,
      reqdesc: '相似推荐',
      id,
    })
  }
}
