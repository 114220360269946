import {format} from 'date-fns'
import Random from './random'

class FilenameGenerator {
  constructor() {
  }

  generatorFileName(userId) {
    let randNumber = Random.instance.MathRand(6);
    let now = new Date();
    let nowDateStr = format(now, 'yyyyMMdd-HHmmss');
    return `u${userId}-${nowDateStr}-${randNumber}`;
  }
}

export default {
  GenericFileNameGenerator: new FilenameGenerator(),
}
