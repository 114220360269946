import axios from '../resources/js/request'

const request = axios.request;

export default {
  /**
   * 添加用户收藏
   * @param userId 用户ID
   * @param targetId 目标ID
   * @param targetType 目标类型 1.店铺 2.源码 3.需求 4.设计 5.服务 6.文档 7.兼职
   * @returns {Promise}
   */
  addFavorite({userId, targetId, targetType}) {
    return request.doPost({
      reqcode: '1067',
      reqdesc: '添加收藏',
      userId: userId,
      targetType: targetType,
      targetId: targetId,
    })
  },
  /**
   * 获取收藏的需求
   */
  getDemand({userId, page, limit}) {
    return request.doPost({
      reqcode: '1087',
      reqdesc: '收藏的需求',
      userId: userId,
      page, limit,
    })
  },
  getDocument({userId, page, limit}) {
    return request.doPost({
      reqcode: '1146',
      reqdesc: '收藏的文档',
      userId: userId,
      page, limit,
    })
  },
  getShop({userId, page, limit}) {
    return request.doPost({
      userId: userId,
      reqcode: '1068',
      reqdesc: '收藏的店铺',
      page, limit,
    });
  },
  getProduct({userId, page, limit}) {
    return request.doPost({
      userId: userId,
      reqcode: '1165',
      reqdesc: '收藏的源码',
      page, limit,
    })
  },
  getPartTime({userId, page, limit}) {
    return request.doPost({
      userId: userId,
      reqcode: '1167',
      reqdesc: '收藏的兼职',
      page, limit,
    })
  },
  getDesign({userId, page, limit}) {
    return request.doPost({
      userId: userId,
      reqcode: '1186',
      reqdesc: '收藏的设计',
      page, limit,
    })
  },
  getService({userId, page, limit}) {
    return request.doPost({
      userId: userId,
      reqcode: '1197',
      reqdesc: '收藏的服务',
      page, limit,
    })
  }
}
