<template>
  <div id="please">

  <div id="pay" >
    <div style="color: black;padding-left: 180px; font-size: 18px;padding-right: 180px;padding-bottom: 10px; font-size: 18px" class="flex-rl" >{{typename}}</div>
    <div style="height: 2px;background-color: #C0C4CC;width: 100%;opacity: 0.3;"></div>
   <div class="flex-rc" :class="{'show':show}">
     <div>
       <div style="padding: 5px 0">
         <div style="padding: 5px 0;color: black"><h3 >请使用{{typename}} [ 扫一扫 ] </h3></div>
         <div style="padding: 5px 0"><h3>扫描二维码支付&nbsp;<w style="color: red;font-size: 25px">{{price}}</w>&nbsp;元</h3></div>
       </div>
       <div style="padding: 10px 0 ">
         <el-image
           src="/images/temp/zfbsrcode.png"
           fit="cover"
           style="box-shadow: 0 2px 4px rgba(0,0,0, .12),0 0 6px rgba(0,0,0, .04);width: 250px;height: 250px"
           v-if="typename=='支付宝支付'"

         ></el-image>
<!--         <el-image-->
<!--           id="wxImg"-->
<!--           src="/images/temp/wxsrcode.png"-->
<!--           fit="cover"-->
<!--           style="box-shadow: 0 2px 4px rgba(0,0,0, .12),0 0 6px rgba(0,0,0, .04);width: 230px;height: 230px"-->
<!--           v-if="typename=='微信支付'"-->
<!--         ></el-image>-->
         <img id="wxImg" v-if="typename=='微信支付'">

       </div>
       <div><h5>二维码有效期时长为1小时，请尽快支付</h5></div>
       <div><h5>如无法支付，请点击<el-link type="primary" @click="getQrCode">刷新</el-link></h5></div>
     </div>
     <div >
       <el-image

         src="/images/temp/zfbscreen.png"
         fit="cover"
         style="box-shadow: 0 2px 4px rgba(0,0,0, .12),0 0 6px rgba(0,0,0, .04)"
         v-if="typename=='支付宝支付'"
       ></el-image>
       <el-image

         src="/images/temp/wxscreen.png"
         fit="cover"
         style="box-shadow: 0 2px 4px rgba(0,0,0, .12),0 0 6px rgba(0,0,0, .04);width: 300px;height: 350px;"
         v-if="typename=='微信支付'"
       ></el-image>
     </div>
   </div>
<!--    <div :class="{'show':show1}">-->
<!--      <div style="padding: 50px 0">-->
<!--        <div style="color: #3fdeae;padding: 10px 0"><i class="el-icon-success" style="font-size: 80px"></i></div>-->
<!--        <div style="padding: 15px 0"></div>-->
<!--        <div style="font-size: 25px;padding: 5px 0 ;color: #303133">支付成功</div>-->
<!--        <div style="font-size: 15px;padding: 10px 0">如需开具发票，请前往<el-link type="primary" style="color: #0e7fe1;font-size: 16px"> 订单页 </el-link>进行开具</div>-->
<!--        <div style="padding: 15px 0"></div>-->
<!--        <div ><el-button type="primary" size="medium" style="width: 80px">确定</el-button></div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  </div>
</template>

<script>
import userInfo from "../../../views/users-center-manage/user-manage/user/UserInfoMain";

export default {
  props:['price','typename'],
  created() {
    // this.$request.post({
    //   reqcode: '1272',
    //   reqdesc: '首页参数',
    //   appid:TEST
    // }).then(res => {
    //   console.log(data)
    // })
  },
  data() {
    return {
      show:false,
      show1:true,
      timer:null,
      uri:'',
    }

  },
  methods:{
    getQrCode(){
        clearInterval(this.timer)
      this.$request.post({
        reqcode: '1275',
        reqdesc: '获取二维码',
        price : this.price,
        title : this.typename,
        userId : this.user.userId
      }).then(res=>{
        new QRious({
          element:document.getElementById('wxImg'),
          size:220,
          level:'H',
          value:res.data.code_url
        });
        this.getQrCodeSta(res.data.out_trade_no)
      })
    },
    getQrCodeSta(outOrderId){
      this.timer = setInterval(()=>{
        this.$request.post({
          reqcode: '1276',
          reqdesc: '获取二维码的状态',
          outTradeNo : outOrderId,
          userid : this.user.userId
        }).then(response=>{
          if (response.data == "SUCCESS"){
            clearInterval(this.timer)
            this.$message.success("支付成功")
            this.$Bus.$emit("payStatus",true)
          }
        })
      },3000)
    }
  },
  activated() {
    if (this.typename == '微信支付'){
      this.getQrCode()
    }
  },
  deactivated() {
    clearInterval(this.timer)
    this.timer =null
  }

}
</script>
<style scoped lang="scss">

#please{
  margin: 0 0;
  text-align: center;
  padding: 0 0;
  height: 500px;
}

.flex-rc{
  display: flex;
  flex-direction:row ;
  justify-content: space-around;
}
.flex-cc{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flex-rl{
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.show{
  display: none;
}
</style>
