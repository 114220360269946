class Filter {
  doFilter() {

  }
}

class FilterChain {
  constructor() {
    this.filters = [];
  }

  addFilter(filter) {
    this.filters.push(filter);
  }

  execute(request) {
    for (let filter of this.filters) {
      filter.execute(request);
    }
  }

}

export default {
  Filter,
  FilterChain
}

