import axios from '../resources/js/request'
import router from "@ROUTE/index";

const request = axios.request;

export default {
  getCarts({userId}) {
    return request.doPost({
      reqcode: '1019',
      reqdesc: '购物车列表',
      userId: userId
    });
  },
  updateGoodsNumber({cartId, amount}) {
    return request.doPost({
      reqcode: '1020',
      reqdesc: '修改购物车商品数量',
      cartId: cartId,
      amount: amount
    });
  },
  delCart({cartId}) {
    return request.doPost({
      reqcode: '1021',
      reqdesc: '删除购物车',
      cartId: cartId
    });
  },
  clearCart({userId}) {
    return request.doPost({
      reqcode: '1134',
      reqdesc: '清空购物车',
      userId,
    });
  },
  cartList({cartIds}) {
    return request.doPost({
      reqcode: '1022',
      reqdesc: '购物车列表',
      cartIds,
    });
  },
  buyNow({id, type, amount}) {
    return request.doPost({
      reqdesc: 'buy now',
      reqcode: 1040,
      id, type, amount
    }).then(res => {
      let {data} = res;
      router.push({
        path: '/order/buy/12',
        query: {
          cartGroup: JSON.stringify([data]),
        }
      });
    });
  },
  cartNumber({userId}) {
    return request.doPost({
      reqcode: '1075',
      reqdesc: '购物车和消息未读数',
      userId,
    })
  }
}
