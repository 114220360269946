import CryptoJS from 'crypto-js'
import config from "@/resources/js/config";
// 秘钥，必须由16位字符组成
let secretKey = config.secretKey;

export const AESUtil = {
  /**
   * AES加密方法
   * @param content 要加密的字符串
   * @returns {string} 加密结果
   */
  aesEncrypt: (content) => {
    let key = CryptoJS.enc.Utf8.parse(secretKey);
    let srcs = CryptoJS.enc.Utf8.parse(content);
    let encrypted = CryptoJS.AES.encrypt(srcs, key,
      {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
  },

  /**
   * 解密方法
   * @param encryptStr 密文
   * @returns {string} 明文
   */
  aesDecrypt: (encryptStr) => {
    let key = CryptoJS.enc.Utf8.parse(secretKey);
    let decrypt = CryptoJS.AES.decrypt(encryptStr, key,
      {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  }
}

export const CryptoUtil = {
  // AES 加密，AES-128, AES-192, AES-256
// AES-128: key和iv都是16个字节，16*8=128bit
// AES-192: key和iv都是24个字节，24*8=128bit
// AES-256: key和iv都是32个字节，32*8=128bit
// iv 似乎一般都是纯数字
// 例如：AES_encrypt_CBC('需要加密的内容', '1234567890123456', '1234567890654321');
  AES_CBC_encrypt(message, iv) {
    // utf8字符串—>WordArray对象，WordArray是一个保存32位整数的数组，相当于转成了二进制
    let keyHex = CryptoJS.enc.Utf8.parse(secretKey); //
    let ivHex = CryptoJS.enc.Utf8.parse(iv);
    let messageHex = CryptoJS.enc.Utf8.parse(message);
    let encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
      iv: ivHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();// base64结果
    //return encrypted.ciphertext.toString();   // 二进制结果
  },
  // AES CBC模式解密
  AES_CBC_decrypt(word, iv) {
    // 如果加密后没有转成base64,那么先要转成base64再传入
    let encryptedHexStr = CryptoJS.enc.Hex.parse(word);   // 从二进制文本转成二进制
    let messageBase64 = CryptoJS.enc.Base64.stringify(encryptedHexStr);    // 转成base64
    let keyHex = CryptoJS.enc.Utf8.parse(secretKey);
    let ivHex = CryptoJS.enc.Utf8.parse(iv);
    let decrypt = CryptoJS.AES.decrypt(messageBase64, keyHex, {
      iv: ivHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  },
  // AES ECB模式加密，没有iv
  AES_ECB_encrypt(message) {
    // utf8字符串—>WordArray对象，WordArray是一个保存32位整数的数组，相当于转成了二进制
    let keyHex = CryptoJS.enc.Utf8.parse(secretKey); //
    let messageHex = CryptoJS.enc.Utf8.parse(message);
    let encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();// base64结果
    //return encrypted.ciphertext.toString();   // 二进制结果
  },
  // AES ECB模式解密
  AES_ECB_decrypt(word) {
    // 如果加密后没有转成base64,那么先要转成base64再传入
    let encryptedHexStr = CryptoJS.enc.Hex.parse(word);   // 从二进制文本转成二进制
    let messageBase64 = CryptoJS.enc.Base64.stringify(encryptedHexStr);    // 转成base64
    let keyHex = CryptoJS.enc.Utf8.parse(secretKey);
    let decrypt = CryptoJS.AES.decrypt(messageBase64, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  }
}
