import axios from '../resources/js/request'

const request = axios.request;

export default {
  listMessage({userId, page, limit, messageType, status}) {
    return request.doPost({
      reqcode: '1174',
      reqdesc: '获取用户消息',
      userId, page, limit, messageType, status,
    })
  },
  messageNotice({userId}) {
    return request.doPost({
      reqcode: '1203',
      reqdesc: '获取消息未读数',
      userId,
    })
  }
}
