import axios from '../resources/js/request'

const request = axios.request;

export default {
  similarRecommendation({serviceId}) {
    return request.doPost({
      reqcode: '1196',
      reqdesc: '相似服务推荐',
      serviceId,
    });
  },
  submitOrder({
    serviceId, userId, phone, expectDate,
    remark, annex, budget
  }) {
    return request.doPost({
      reqcode: '1135',
      reqdesc: '提交服务订单',
      serviceId: serviceId,
      userId: userId,
      phone: phone,
      expectDate: expectDate,
      remark: remark,
      annex: annex,
      budget: budget,
    })
  },
  orderDetail({orderId}) {
    return request.doPost({
      reqcode: 1198,
      reqdesc: '服务订单信息',
      orderId: orderId,
      invalid_cache: new Date().getTime(),
    });
  },
  payHosting({orderId, amount, userId, payWay}) {
    return request.doPost({
      reqcode: 1199,
      reqdesc: '支付托管资金',
      orderId, amount, userId, payWay
    })
  },
  confirmService({orderId, userId}) {
    return request.doPost({
      reqcode: 1212,
      reqdesc: '确认完成服务',
      orderId, userId,
      status: 5
    })
  },
  sellerOrder({userId, page, limit, createStart, createEnd, orderNo, status}) {
    return request.doPost({
      reqdesc: '商家服务订单',
      reqcode: 1211,
      userId, page, limit, createStart, createEnd, orderNo, status,
    });
  },
  userOrder({userId, page, limit, createStart, createEnd, orderNo, status}) {
    return request.doPost({
      reqdesc: '用户服务订单',
      reqcode: 1210,
      userId, page, limit, createStart, createEnd, orderNo, status,
    });
  },
  /**
   * 用户确认支付托管金额
   * @param orderId 订单ID
   * @param userId 用户ID
   */
  confirmPayHosting({orderId, userId}) {
    return request.doPost({
      reqcode: 1212,
      reqdesc: '确认支付',
      orderId, userId,
      status: 6
    })
  },
  /**
   * 接受服务
   */
  optionsService({orderId, status}) {
    return request.doPost({
      reqcode: 1212,
      reqdesc: '服务状态',
      orderId, status,
    })
  },
  upperPay({serviceId, userId, payWay, date}) {
    return request.doPost({
      reqcode: '1117',
      reqdesc: '服务上架',
      serviceId, userId, payWay, date,
    })
  },
  submitEvaluate({orderId, score, content, userId, pictures, serviceAttitudeScore, completeStatusScore, deliveryQualityScore, technicalAbilityScore, communicatioScore,}) {
    return request.doPost({
      reqcode: 1221,
      reqdesc: 'service order evaluate',
      orderId,
      score,
      content,
      userId,
      pictures,
      serviceAttitudeScore,
      completeStatusScore,
      deliveryQualityScore,
      technicalAbilityScore,
      communicatioScore,
    })
  },
  getComment({id, userId, page, limit}) {
    return request.doPost({
      reqcode: 1226,
      reqdesc: 'load service comment',
      id, userId, page, limit,
    })
  },
  serviceInfo({id, userId}) {
    return request.doPost({
      reqcode: 1227,
      reqdesc: 'load service info',
      id, userId
    })
  },
  serviceCenter({page, limit, oneType, twoType, threeType, serviceName, orderBy, priceMin, priceMax,}) {
    return request.doPost({
      reqcode: '1111',
      reqdesc: '服务中心',
      page,
      limit,
      oneType,
      twoType,
      threeType,
      serviceName,
      orderBy,
      priceMin,
      priceMax,
    })
  },
  serviceCenterRecommend() {
    return request.doPost({
      reqcode: '1096',
      reqdesc: 'serviceCenterRecommend'
    })
  },
  refuseCondition() {
    return request.doPost({
      reqcode: 1264,
      reqdesc: 'refuse condition'
    });
  },
  refuseService({userId, orderId, reason}) {
    return request.doPost({
      reqcode: 1265,
      reqdesc: 'refuse service',
      userId, orderId, reason,
    })
  },
  del({serviceId, userId}) {
    return request.doPost({
      reqcode: 1266,
      reqdesc: 'del service',
      serviceId, userId,
    })
  },
  selectUserService(params) {
    return request.doPost({
      reqcode: '1091',
      reqdesc: '获取服务',
      ...params
    })
  },
  orderDetails({orderId, userId}) {
    return request.doPost({
      reqcode: '1062',
      reqdesc: 'order details',
      orderId, userId,
      invalid_cache: new Date().getTime(),
    })
  },
  serverProvider({page, limit, shopName, provinceCode, cityCode, orderBy,}) {
    return request.doPost({
      reqcode: '1112',
      reqdesc: '服务商中心',
      page, limit, shopName, provinceCode, cityCode, orderBy,
    })
  }
}
