// 本地开发环境
const dev = {
  isDebug: true,
  BASE_URL: '/api',    //不需要修改
  TARGET_URL: 'http://localhost:8082/',   //根据实际环境修改
  ADMIN_URL: 'http://localhost:8083/',   //根据实际环境修改
};

// 本地开发环境
const test = {
  isDebug: true,
  BASE_URL: '/api',    //不需要修改
  TARGET_URL: 'http://testapps.local.molanit.cn:8082/',   //根据实际环境修改
  ADMIN_URL: 'http://testadmin.local.molanit.cn:8082/',   //根据实际环境修改
};

// 生产环境
const pro = {
  isDebug: false,
  BASE_URL: '/srapi',
  ADMIN_URL: 'http://admin.molanit.cn',
};

const choose = () => {
  if (process.env.NODE_ENV === 'development') {
    return {
      ...dev,
      isDev: true
    }
  } else if (process.env.NODE_ENV === 'test') {
    return {
      ...test,
      isDev: true
    }
  } else if (process.env.NODE_ENV === 'production') {
    return {
      ...pro,
      isDev: false
    }
  } else {
    return {

    }
  }
};
const env = choose();
const config = {
  ...env,
  APP_ID: 'SCTP_WEB',
  URL: '/doIsrNoEncrypt2022RecvAction',
  API_ROOT: env.BASE_URL,
  secretKey: "yJuYW1lIjoiTmluZ",
  // UPLOAD_API: env.BASE_URL + '/uploads/uploadimgs',
  UPLOAD_API: env.BASE_URL + '/uploads/uploadFile',
  UPLOAD_API2: env.BASE_URL + '/uploads/uploadCKEditor?path=textimg',
  version: '1.0.2'
};
module.exports = config;
