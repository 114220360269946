import axios from '../resources/js/request'

const request = axios.request

/**
 * 首页相关
 */
export default {
  loadData() {
    return request.doPost({
      reqcode: '1016',
      reqdesc: '首页商品',
    });
  },
  statistics() {
    return request.doPost({})
  },
  homeData({type,page, limit}) {
    return request.doPost({
      reqcode: 1240,
      reqdesc: '获取首页数据',
      btype: type,
      page, limit,
    });
  },
  document() {
    return request.doPost({
      reqcode: 1241,
      reqdesc: 'home index document'
    });
  },
  sourceCode() {
    return request.doPost({
      reqcode: 1242,
      reqdesc: 'home index sourceCode'
    });
  },
  demand() {
    return request.doPost({
      reqcode: 1243,
      reqdesc: 'home index demand'
    });
  },
  service() {
    return request.doPost({
      reqcode: 1244,
      reqdesc: 'home index service'
    });
  },
  sellInfo() {
    return request.doPost({
      reqcode: 1274,
      reqdesc: '出售大厅'
    });
  }

}
