// 数据类型增强
// 乘法
Number.prototype.mul = function (arg) {
  let m = 0,
    s1 = this.toString(),
    s2 = arg.toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) {
  }
  try {
    m += s2.split(".")[1].length;
  } catch (e) {
  }
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(
    10, m);
};
// 加法
Number.prototype.add = function (arg) {
  let r1, r2, m;
  try {
    r1 = this.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  return (this.mul(m) + Number(arg).mul(m)).div(m);
};
// 减法
Number.prototype.sub = function (arg) {
  return this.add(-arg);
};
// 除法
Number.prototype.div = function (arg) {
  let t1 = 0,
    t2 = 0,
    r1, r2;
  try {
    t1 = this.toString().split(".")[1].length;
  } catch (e) {
  }
  try {
    t2 = arg.toString().split(".")[1].length;
  } catch (e) {
  }
  r1 = Number(this.toString().replace(".", ""));
  r2 = Number(arg.toString().replace(".", ""));
  return (r1 / r2) * Math.pow(10, t2 - t1);
};
// 四舍五入保留两位 n 数
Number.prototype.toFixed = function (n) {
  let times = Math.pow(10, n);
  let des = this * times + 0.5;
  des = parseInt(des, 10) / times;
  return des + '';
};
Number.prototype.toMoney = function () {
  let num = this;
  if (num || num === 0) {
    if (isNaN(num)) {
      alert('金额中含有不能识别的字符');
      return;
    }
    num = typeof num == 'string' ? parseFloat(num) : num
    num = num.toFixed(2);
    num = parseFloat(num);
    num = num.toLocaleString();
    if (num.indexOf('.') === -1) {
      num = num + '.00';
    }
    return num;
  } else {
    return num = null;
  }
};
// promise 增强
Promise.prototype.finally = function (callback) {
  let P = this.constructor;
  return this.then(
    value => P.resolve(callback()).then(() => value),
    reason => P.resolve(callback()).then(() => {
      throw reason;
    })
  );
};
