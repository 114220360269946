export default {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
    setUser: function (state, user) {
      state.user = user
      localStorage.removeItem('user');
      if (!user) {
        localStorage.removeItem('token');
      }
    }
  },
  getters: {
    getUser: state => {
      return state.user;
    }
  }
}
