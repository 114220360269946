class Random {
  MathRand(size) {
    let str = "";
    for (let i = 0; i < size; i++) {
      str += Math.floor(Math.random() * 10);
    }
    return str;
  }
}

export default {
  Random: Random,
  instance: new Random(),
}
