export default {
  formatDuring(diff) {
    if (diff < 0) {
      return '';
    }
    let ret = '';
    let sec = 1000;
    let minute = sec * 60;
    let hour = minute * 60;
    let day = hour * 24;
    let d = Math.floor(diff / day);
    let h = Math.floor((diff % day) / hour);
    let m = Math.floor((diff % hour) / minute);
    let s = Math.floor((diff % minute) / sec);
    if (d) {
      ret += `${d}天`
    }
    if (h) {
      ret += `${h}时`
    }
    if (m) {
      ret += `${m}分`
    }
    if (ret === '') {
      return `${s}秒`
    }
    return ret;
  },
  timeStampDuring(diff) {
    if (diff < 0) {
      return '';
    }
    let ret = '';
    let sec = 1000;
    let minute = sec * 60;
    let hour = minute * 60;
    let day = hour * 24;
    let d = Math.floor(diff / day);
    let h = Math.floor((diff % day) / hour);
    let m = Math.floor((diff % hour) / minute);
    let s = Math.floor((diff % minute) / sec);
    if (d) {
      ret += `${d}天`
    }
    if (h) {
      ret += `${h}时`
    }
    if (m) {
      ret += `${m}分`
    }
    if (s) {
      ret += `${s}秒`
    }
    return ret;
  }
}
