import axios from '../resources/js/request'

const request = axios.request;

export default {
  cancelOrder({orderId, userId}) {
    return request.doPost({
      reqcode: '1157',
      reqdesc: '取消订单',
      orderId: orderId,
      userId: userId,
    });
  },
  confirmReceipt({orderId, userId}) {
    return request.doPost({
      reqcode: '1095',
      reqdesc: '确认收货',
      orderId: orderId,
      userId: userId,
    });
  },
  deleteOrder({orderId, userId}) {
    return request.doPost({
      reqcode: '1076',
      reqdesc: '删除订单',
      orderId: orderId,
      userId: userId,
    });
  },
  submitOrder({userId, cartIds, address, shops}) {
    return request.doPost({
      reqcode: '1023',
      reqdesc: '提交订单',
      userId, cartIds, address, shops
    });
  },
  getOrder({orderIds, userId}) {
    return request.doPost({
      reqcode: '1079',
      reqdesc: '获取订单详情',
      orderIds: orderIds, userId,
    });
  },
  orderPay({orderIds, payWay, userId}) {
    return request.doPost({
      reqcode: '1064',
      reqdesc: '订单支付',
      orderIds: orderIds.join(','),
      payWay: payWay,
      userId: userId
    });
  },
  transaction({userId, page, limit, orderNo, status, createStart, createEnd, productType}) {
    return request.doPost({
      reqcode: '1056',
      reqdesc: '交易中心',
      userId, page, limit, orderNo, status, createStart, createEnd, productType,
    });
  },
  agreeToRefund({orderId}) {
    return request.doPost({
      reqcode: 1190,
      reqdesc: '订单同意退款',
      orderId,
    });
  },
  refusalToRefund({orderId, rejectReason}) {
    return request.doPost({
      reqcode: 1191,
      reqdesc: '订单拒绝退款',
      orderId, rejectReason
    })
  },
  orderList({userId, page, limit, createStart, createEnd, orderNo, status,}) {
    return request.doPost({
      reqcode: '1025',
      reqdesc: '订单列表',
      userId, page, limit, createStart, createEnd, orderNo, status,
    })
  },
  confirmDelivery({orderId, deliverWay, deliverEmail, deliverLogistics, logisticsOrderNo}) {
    return request.doPost({
      reqcode: 1194,
      reqdesc: '确认发货',
      orderId, deliverWay, deliverEmail, deliverLogistics, logisticsOrderNo
    })
  },
  loadLogistics() {
    return request.doPost({
      reqcode: 1209,
      reqdesc: 'logistic',
    });
  },
  orderDetail({userId, orderId}) {
    return request.doPost({
      reqcode: 1256,
      reqdesc: 'order detail',
      orderId, userId,
    });
  },
  salesAfterOrderDetail({userId, orderId}) {
    return request.doPost({
      reqcode: 1258,
      reqdesc: 'order detail',
      orderId, userId,
    });
  },
  loadSalesAfterCondition() {
    return request.doPost({
      reqdesc: 'load condition',
      reqcode: 1260,
    })
  },
  submitSalesAfter({userId, orderId, type, reason, detail, picture, username, phone, address}) {
    return request.doPost({
      reqdesc: 'submit sales after request',
      reqcode: 1259,
      userId, orderId, type, reason, detail, picture, username, phone, address,
    });
  },
  salesAfterAgree({userId, orderId}) {
    return request.doPost({
      reqcode: 1261,
      reqdesc: 'salesAfterAgree',
      userId, orderId
    })
  },
  salesAfterReject({userId, orderId}) {
    return request.doPost({
      reqcode: 1262,
      reqdesc: 'salesAfterReject',
      userId, orderId
    })
  },
  salesAfterRevoke({userId, orderId}) {
    return request.doPost({
      reqcode: 1263,
      reqdesc: 'salesAfterRevoke',
      userId, orderId
    })
  },
  changeOrderPrice({orderId, userId, price}) {
    return request.doPost({
      reqcode: 1267,
      reqdesc: 'change order price',
      orderId, userId, price
    })
  },
  sendCustomerServiceChat({userId, content, orderId, chatType}) {
    return request.doPost({
      reqcode: 1270,
      reqdesc: 'customer service chat',
      userId, content, orderId, chatType,
    })
  },
  listCustomerServiceChat({orderId}) {
    return request.doPost({
      reqcode: 1271,
      reqdesc: 'list customer service chat',
      orderId,
    })
  }
}

